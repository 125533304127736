import classNames from 'classnames'

import Button from '../Button/Button'
import Heading from '../Heading/Heading'
import Text from '../Text/Text'

import styles from './CustomSolution.module.scss'

const CustomSolution = () => (
  <section className={classNames('container section', styles.main)}>
    <div className={classNames('d-flex-center narrow-content', styles.content)}>
      <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.heading)}>
        Need a custom solution or pricing?
      </Heading>
      <Text className={classNames('text1', styles.description)}>
        Our team can tailor a solution to your specific needs, ensuring optimal protection for your enterprise.
      </Text>
      <Button to="/contact" className={styles.button} wide>
        Contact sales
      </Button>
    </div>
  </section>
)

export default CustomSolution

import classNames from 'classnames'

import AnimateOnScroll from '../../../../components/AnimateOnScroll/AnimateOnScroll'
import Heading from '../../../../components/Heading/Heading'
import ResponsiveImage from '../../../../components/ResponsiveImage/ResponsiveImage'
import Text from '../../../../components/Text/Text'

import styles from './Scenarios.module.scss'

const SCENARIOS_DATA = {
  messages: {
    title: 'Checks suspicious messages and emails for you',
    caption: 'Don\'t fall victim to phishing scams. Let us check your messages.',
  },
  links: {
    title: 'Confirms links or files shared with you are safe to open',
    caption: 'Avoid malware infections. Verify links before clicking.',
  },
  profiles: {
    title: 'Verifies user profiles are not fake or fishing',
    caption: 'Protect yourself from online impersonators. Verify profiles with ease.',
  },
  photos: {
    title: 'Checks if photos are not borrowed from another source',
    caption: 'Spot fake images and avoid scams. Verify image authenticity.',
  },
}

const Scenarios = () => (
  <div className={classNames('container d-flex-center', styles.main)}>
    {Object.entries(SCENARIOS_DATA).map(([key, value]) => (
      <AnimateOnScroll key={key} className={classNames('d-flex-center', styles.item)}>
        <Heading className={classNames('heading mobile-h2 desktop-h2 narrow-content', styles.title)} as="h2">
          {value.title}
        </Heading>
        <ResponsiveImage image={key} className={styles.image} />
        <Text className={classNames('text1', styles.caption)}>
          {value.caption}
        </Text>
      </AnimateOnScroll>
    ))}
  </div>
)

export default Scenarios

import classNames from 'classnames'

import Button from '../Button/Button'
import Heading from '../Heading/Heading'
import Text from '../Text/Text'

import styles from './Hero.module.scss'

interface IHeroProps {
  title: string;
  subtitle: string;
  description: string;
  buttonLabel: string;
}

const Hero = ({
  title,
  subtitle,
  description,
  buttonLabel,
}: IHeroProps) => (
  <section className={classNames('d-flex container', styles.main)}>
    <div className={classNames('d-flex', styles.content)}>
      <Heading className={classNames('heading mobile-h1 desktop-h1', styles.title)} as="h1">
        {title}
      </Heading>
      <Text className={classNames('text1', styles.description)}>
        {description}
      </Text>
      <Text className={classNames('heading mobile-subheading desktop-subheading', styles.subtitle)}>
        {subtitle}
      </Text>
      <p className={styles.button}>
        <Button to="/contact" wide>
          {buttonLabel}
        </Button>
      </p>
    </div>
    <img src="/assets/hero.png" alt="Hold the Door" className={styles.image} />
    <div className={styles.cursor} />
  </section>
)

export default Hero

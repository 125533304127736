import Brandbar from '../Brandbar/Brandbar'

import styles from './Header.module.scss'

const LINKS = {
  personal: {
    to: '/',
    label: 'Personal',
  },
  enterprise: {
    to: '/enterprise',
    label: 'Enterprise',
  },
  pricing: {
    to: '/pricing',
    label: 'Pricing',
  },
  contact: {
    to: '/contact',
    label: 'Contact us',
  },
}

const Header = () => (
  <Brandbar as="header" links={LINKS} className={styles.main} />
)

export default Header

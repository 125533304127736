import classNames from 'classnames'
import { PropsWithChildren } from 'react'

import styles from './Badge.module.scss'

interface IBadgeProps {
  className?: string;
}

const Badge = ({ className, children }: PropsWithChildren<IBadgeProps>) => (
  <span className={classNames('caption', styles.main, className)}>
    {children}
  </span>
)

export default Badge

import classNames from 'classnames'
import { Field } from 'react-final-form'

import Text from '../Text/Text'
import FormError from './FormError'
import { getFieldError } from './FormField.utils'

import styles from './Input.module.scss'

interface IInputProps {
  id: string;
  label: string;
  type?: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
}

const Input = ({
  label,
  className,
  id,
  maxLength,
  type = 'text',
  placeholder = label,
}: IInputProps) => (
  <Field
    name={id}
    render={({ input, meta }) => (
      <label
        htmlFor={id}
        className={classNames('d-flex heading-h200', styles.main, !!getFieldError(meta) && styles.error)}
      >
        <Text className={styles.label}>
          {label}
        </Text>
        <input
          {...input}
          id={id}
          type={type}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={meta.submitting}
          className={classNames('ui-text', styles.input, className)}
        />
        <FormError name={input.name} />
      </label>
    )}
  />
)

export default Input

const PERSONAL_PRICING = {
  basic: {
    name: 'Basic',
    monthly: {
      price: '',
      trialPrice: 'Free',
      term: '',
    },
    annually: {
      price: '',
      trialPrice: 'Free',
      term: '',
    },
    description: 'Ideal for personal use and testing the service\'s features',
    package: [
      'Basic fraud detection',
      'Standard fraud classification',
      '20 checks per month',
    ],
  },
  plus: {
    name: 'Plus',
    monthly: {
      price: '$5',
      trialPrice: '$0',
      term: 'first month',
    },
    annually: {
      price: '',
      trialPrice: '$50',
      term: 'per year',
    },
    description: 'For users needing more frequent access to fraud checks',
    package: [
      'Detailed fraud classification results',
      'Actionable insights',
      '100 checks per month',
    ],
  },
  advanced: {
    name: 'Advanced',
    monthly: {
      price: '$15',
      trialPrice: '$0',
      term: 'first month',
    },
    annually: {
      price: '',
      trialPrice: '$150',
      term: 'per year',
    },
    description: 'For advanced users needing flexibility and frequent checks',
    package: [
      'No daily limits',
      'Early access to new features',
      '500 checks per month',
    ],
  },
}

export default PERSONAL_PRICING

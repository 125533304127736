import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Button from '../Button/Button'
import Text from '../Text/Text'

import styles from './Brandbar.module.scss'

interface IBrandbarLink {
  to: string;
  label: string;
  isNewTab?: boolean;
  preventActiveClassName?: boolean;
}

type BrandbarParentEl = 'header' | 'footer'

interface IBrandbarProps {
  as: BrandbarParentEl;
  links: Record<string, IBrandbarLink>;
  className?: string;
}

const Brandbar = ({ as: ParentEl, links, className }: IBrandbarProps) => (
  <ParentEl className={classNames(styles.main, styles[ParentEl], className)}>
    <div className={classNames('layout container', styles.inner)}>
      <Link to="/" className={styles.logo}>
        <Text as="span" className="space-grotesk">
          Hold the Door
        </Text>
      </Link>
      <nav className={styles.links}>
        {Object.entries(links).map(([key, value]) => (
          <li key={key}>
            <Button
              to={value.to}
              type="tetriary"
              className={styles.link}
              isNewTab={value.isNewTab}
              preventActiveClassName={value.preventActiveClassName}
            >
              {value.label}
            </Button>
          </li>
        ))}
      </nav>
    </div>
  </ParentEl>
)

export default Brandbar

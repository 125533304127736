import AnimateOnScroll from '../../components/AnimateOnScroll/AnimateOnScroll'
import Page from '../../components/Page/Page'
import ROUTES from '../../constants/routes.constants'
import ContactForm from './components/ContactForm/ContactForm'

const ContactUsRoute = () => (
  <Page route={ROUTES.contact}>
    <AnimateOnScroll>
      <ContactForm />
    </AnimateOnScroll>
  </Page>
)

export default ContactUsRoute

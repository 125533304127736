import classNames from 'classnames'
import { useField } from 'react-final-form'

import { ReactComponent as ErrorIcon } from '../../assets/icons/error-icon.svg'
import { getFieldError } from './FormField.utils'

import style from './FormError.module.scss'

interface IFormErrorProps {
  name: string;
}

const FormError = ({ name }: IFormErrorProps) => {
  const { meta } = useField(name)
  const error = getFieldError(meta)

  if (!error) {
    return null
  }

  return (
    <p className={classNames('svg-current-color ui-text', style.main)}>
      <ErrorIcon />
      {error}
    </p>
  )
}

export default FormError

import classNames from 'classnames'

import { LAPTOP_BREAKPOINT } from '../../constants/viewport.constants'

interface IResponsiveImageProps {
  image: string;
  className?: string;
}

const ResponsiveImage = ({ image, className }: IResponsiveImageProps) => (
  <picture>
    <source media={`(min-width: ${LAPTOP_BREAKPOINT}px)`} srcSet={`/assets/${image}-desktop.png`} />
    <img className={classNames(className)} src={`/assets/${image}.png`} alt={image.toLocaleUpperCase()} />
  </picture>
)

export default ResponsiveImage

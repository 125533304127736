import Brandbar from '../Brandbar/Brandbar'

const LINKS = {
  personal: {
    isNewTab: true,
    to: '/docs/hold_the_door_privacy_policy.pdf',
    label: 'Privacy',
  },
  enterprise: {
    isNewTab: true,
    to: '/docs/hold_the_door_terms.pdf',
    label: 'Terms',
  },
  contact: {
    to: '/contact',
    label: 'Contact us',
    preventActiveClassName: true,
  },
}

const Footer = () => (
  <Brandbar as="footer" links={LINKS} />
)

export default Footer

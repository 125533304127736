import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'

import Badge from '../Badge/Badge'

import styles from './Switcher.module.scss'

export type SwitcherValue = 'left' | 'right'

interface ISwitcherProps {
  left: string;
  right: string;
  leftBadge?: string;
  rightBadge?: string;
  defaultValue?: SwitcherValue;
  disabled?: boolean;
  onSwitch?: (selected: SwitcherValue) => void;
}

const Switcher = ({
  onSwitch,
  left,
  right,
  leftBadge,
  rightBadge,
  defaultValue = 'left',
  disabled = false,
}: ISwitcherProps) => {
  const [active, setActive] = useState<SwitcherValue>(defaultValue)
  const isLeftActive = useMemo(() => active === 'left', [active])

  useEffect(() => {
    setActive(defaultValue)
  }, [defaultValue])

  const handleSwitch = useCallback((selected: SwitcherValue) => {
    if (onSwitch) {
      onSwitch(selected)
    }

    setActive(selected)
  }, [onSwitch])

  const setLeft = useCallback(() => handleSwitch('left'), [handleSwitch])
  const setRight = useCallback(() => handleSwitch('right'), [handleSwitch])

  return (
    <div className={styles.main}>
      <span className={classNames(styles.background, styles[active], disabled && styles.disabled)} />
      <button
        onClick={setLeft}
        className={classNames('text2', styles.item, styles.left, isLeftActive && styles.active)}
      >
        {left}
        {leftBadge && (
          <Badge className={styles.badge}>
            {leftBadge}
          </Badge>
        )}
      </button>
      <button
        onClick={setRight}
        className={classNames('text2', styles.item, styles.right, !isLeftActive && styles.active)}
      >
        {right}
        {rightBadge && (
          <Badge className={styles.badge}>
            {rightBadge}
          </Badge>
        )}
      </button>
    </div>
  )
}

export default Switcher

import classNames from 'classnames'
import { FORM_ERROR } from 'final-form'
import { useEffect, useState } from 'react'
import { Form, useForm } from 'react-final-form'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from '../../../../components/FormField/Input'
import Textarea from '../../../../components/FormField/Textarea'
import Heading from '../../../../components/Heading/Heading'
import SubmitButton from '../../../../components/SubmitButton/SubmitButton'
import Text from '../../../../components/Text/Text'
import Toast from '../../../../components/ToastContainer/Toast'

import styles from './ContactForm.module.scss'

interface IContactFormValues {
  name: string;
  email: string;
  message: string;
}

interface IContactFormResponse {
  success: boolean;
  message?: string;
}

interface IContactFormInternalProps {
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
}

const API_URL = process.env.REACT_APP_API_URL

const CONTACT_FORM_INITIAL_VALUES: IContactFormValues = {
  name: '',
  email: '',
  message: '',
}

const ContactFormSuccessToast = () => (
  <Toast title="Your message is on its way!" message="We'll get back to you shortly." />
)

const ContactFormInternal = ({ handleSubmit }: IContactFormInternalProps) => {
  const { reset, getState } = useForm()
  const { submitSucceeded, submitFailed, submitError } = getState()
  const [formSubmitFailed, setSubmitFailed] = useState(submitFailed)

  useEffect(() => {
    setSubmitFailed(submitFailed)
  }, [submitFailed])

  useEffect(() => {
    if (submitSucceeded) {
      toast.success(<ContactFormSuccessToast />)
      reset()
    } else if (formSubmitFailed) {
      toast.error(submitError)
      setSubmitFailed(false)
    }
  }, [reset, submitError, formSubmitFailed, submitSucceeded])

  return (
    <form onSubmit={handleSubmit} className={classNames('d-flex', styles.form)}>
      <Heading as="h1" className={classNames('heading mobile-h1 desktop-h1')}>
        Let's build a safer online world together
      </Heading>
      <Text className={classNames('text1', styles.email)}>
        You can reach us anytime via
        <Link to="mailto:hello@holddoor.com" className={styles.link}>
          hello@holddoor.com
        </Link>
      </Text>
      <fieldset className={classNames('d-flex', styles.fieldset)}>
        <Input id="name" placeholder="Your name" label="Name" maxLength={100} />
        <Input id="email" placeholder="your@company.com" label="Email" />
        <Textarea
          id="message"
          label="How can we help?"
          placeholder="Share your specific needs…"
        />
      </fieldset>
      <SubmitButton className={styles.submit} wide>
        Send message
      </SubmitButton>
    </form>
  )
}

const ContactForm = () => {
  const onSubmit = async (values: IContactFormValues) => {
    try {
      const response = await fetch(`${API_URL}/v1/send-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      if (response.status === 429) {
        return { [FORM_ERROR]: 'Too many requests' }
      }

      const data: IContactFormResponse = await response.json()

      if (!data.success) {
        return data
      }

      return null
    } catch (error) {
      console.error('ERROR', error)

      return { [FORM_ERROR]: 'Internal server error. Try again later' }
    }
  }

  return (
    <section className={classNames('section container', styles.main)}>
      <div className={styles.content}>
        <img src="/assets/cursor.png" alt="Contact us" className={styles.image} />
        <Form
          className={styles.form}
          onSubmit={onSubmit}
          initialValues={CONTACT_FORM_INITIAL_VALUES}
          render={({ handleSubmit }) => (
            <ContactFormInternal handleSubmit={handleSubmit} />
          )}
        />
      </div>
    </section>
  )
}

export default ContactForm

import Benefits from '../../components/Benefits/Benefits'
import Hero from '../../components/Hero/Hero'
import Page from '../../components/Page/Page'
import Pricing from '../../components/Pricing/Pricing'
import ROUTES from '../../constants/routes.constants'
import Scenarios from './components/Scenarios/Scenarios'

const BENEFITS = {
  easy: {
    title: 'Easy to Use',
    description: 'Simply paste or upload content, and we\'ll do the rest',
  },
  database: {
    title: 'Expanding Threat Database',
    description: 'We continuously update our database to stay ahead of emerging scams',
  },
  ai: {
    title: 'AI-Integrated',
    description: 'Our advanced AI technology provides accurate and fast results',
  },
  tailored: {
    title: 'Tailored Approach',
    description: 'Our service adapts to your needs, providing personalized recommendations',
  },
}

const HomeRoute = () => (
  <Page route={ROUTES.home}>
    <Hero
      title="Spot scams fast. Protect yourself online."
      description={`
        Hold the Door is your AI-powered assistant, here to help you stay safe online. Simply send us any suspicious
        message, link, or photo, and we'll quickly analyze it for potential scams.
      `}
      subtitle="Free to use. Easy to try. Start now."
      buttonLabel="Get started free"
    />
    <Scenarios />
    <Benefits data={BENEFITS} />
    <Pricing />
  </Page>
)

export default HomeRoute

import Benefits from '../../components/Benefits/Benefits'
import CustomSolution from '../../components/CustomSolution/CustomSolution'
import Hero from '../../components/Hero/Hero'
import Page from '../../components/Page/Page'
import Pricing from '../../components/Pricing/Pricing'
import ROUTES from '../../constants/routes.constants'
import Features from './components/Features/Features'

const BENEFITS = {
  security: {
    title: 'Enhanced security',
    description: 'Protect your business and customers from threats',
  },
  risk: {
    title: 'Reduced risk',
    description: 'Mitigate financial loss and reputation damage',
  },
  efficiency: {
    title: 'Increased efficiency',
    description: 'Streamline your Know-Your-Customer processes',
  },
  solution: {
    title: 'Scalable solution',
    description: 'Easily adapt to your growing needs',
  },
}

const EnterpriseRoute = () => (
  <Page route={ROUTES.enterprise}>
    <Hero
      title="Protect your business with fraud detection."
      description={`
        Our AI-powered platform offers real-time threat detection, customizable API integration, and comprehensive data
        analysis to help you safeguard your business from fraud.
      `}
      subtitle="Protect business. Prevent fraud."
      buttonLabel="Talk to our team"
    />
    <Features />
    <Benefits data={BENEFITS} />
    <Pricing pricingType='enterprise' />
    <CustomSolution />
  </Page>
)

export default EnterpriseRoute

const ENTERPRISE_PRICING = {
  basic: {
    name: 'Basic for Business',
    monthly: {
      price: '$99',
      trialPrice: '$0',
      term: 'first month',
    },
    annually: {
      price: '',
      trialPrice: '$950',
      term: 'per year',
    },
    description: 'Perfect for small businesses requiring regular fraud checks',
    package: [
      'Advanced fraud reporting tools',
      'API access',
      '1,000 checks per month',
    ],
  },
  plus: {
    name: 'Advanced for Business',
    monthly: {
      price: '$299',
      trialPrice: '$0',
      term: 'first month',
    },
    annually: {
      price: '',
      trialPrice: '$2,900',
      term: 'per year',
    },
    description: 'Designed for larger businesses with high volumes of fraud checks',
    package: [
      'No daily limits',
      'Custom fraud prevention dashboard',
      '10,000 checks per month',
    ],
  },
  advanced: {
    name: 'Enterprise',
    monthly: {
      price: '',
      trialPrice: 'Contact Us',
      term: '',
    },
    annually: {
      price: '',
      trialPrice: 'Contact Us',
      term: '',
    },
    description:
      'Tailored enterprise solutions with extensive fraud database access',
    package: [
      'Customizable API integration',
      'On-site consultation and support',
      'Unlimited fraud checks',
    ],
  },
}

export default ENTERPRISE_PRICING

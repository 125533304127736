import { useEffect, useRef, useState } from 'react'

const useIntersectionObserver = (threshold = 0.1) => {
  const elementRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const currentEl = elementRef.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.unobserve(entry.target)
        }
      },
      { threshold },
    )

    if (currentEl) {
      observer.observe(currentEl)
    }

    return () => {
      if (observer && currentEl) {
        observer.unobserve(currentEl)
      }
    }
  }, [threshold])

  return { elementRef, isVisible }
}

export default useIntersectionObserver

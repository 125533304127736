import CustomSolution from '../../components/CustomSolution/CustomSolution'
import Page from '../../components/Page/Page'
import Pricing from '../../components/Pricing/Pricing'
import ROUTES from '../../constants/routes.constants'

import styles from './Pricing.module.scss'

const PricingRoute = () => (
  <Page route={ROUTES.pricing}>
    <div className={styles.main}>
      <Pricing />
      <CustomSolution />
    </div>
  </Page>
)

export default PricingRoute

import { FieldMetaState } from 'react-final-form'

const FORM_ERROR_CODE_MAP: Record<string, string> = {
  'string.empty': 'This field is required',
  'string.min': 'Please provide at least 2 characters',
  'string.email': 'Please provide a correct email address',
  'string.max': 'Maximum 3000 characters',
}

// eslint-disable-next-line @typescript-eslint/comma-dangle, import/prefer-default-export
export const getFieldError = <T,>({
  error,
  submitError,
  dirty,
  modified,
  dirtySinceLastSubmit,
  modifiedSinceLastSubmit,
}: FieldMetaState<T>): string | undefined => {
  const dirtyAndModified = dirty && modified
  const dirtyAndModifiedSinceLastSubmit = dirtySinceLastSubmit && modifiedSinceLastSubmit
  const errorValidation = dirtyAndModified ? error : undefined
  const errorSubmit = !dirtyAndModifiedSinceLastSubmit ? submitError : undefined

  const errorCode: string = errorValidation || errorSubmit

  if (errorCode) {
    return FORM_ERROR_CODE_MAP[errorCode] || errorCode
  }

  return errorCode
}

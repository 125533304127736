/* eslint-disable max-len */
import { IMeta } from '../types/meta.types'
import ROUTES from './routes.constants'

export const DEFAULT_META: IMeta = {
  title: 'Hold the Door - AI-powered Anti-Fraud System for Messages, Links, and Images',
  description: 'Hold the Door is an AI-powered platform designed to detect and prevent fraud in messages, links, and images. Protect yourself from scams with real-time verification across email, SMS, social media, and messaging apps.',
  keywords: 'anti-fraud, fraud detection, AI fraud prevention, scam detection, link verification, message verification, image authenticity, phishing protection, cybersecurity, online security, Hold the Door',
  image: 'https://holddoor.com/assets/hero.png',
  url: 'https://holddoor.com',
}

export default {
  [ROUTES.home]: DEFAULT_META,
  [ROUTES.enterprise]: {
    ...DEFAULT_META,
    title: 'Hold the Door - Enterprise AI-powered Fraud Detection for Businesses',
    description: 'Hold the Door offers AI-powered fraud detection and prevention solutions for enterprises. With real-time threat alerts, customizable API integration, and comprehensive data analysis, protect your business from fraud and minimize risk with seamless, scalable security solutions.',
    keywords: 'enterprise fraud detection, AI fraud prevention, customizable API integration, real-time threat alerts, data analysis, fraud protection, business cybersecurity, KYC processes, fraud prevention strategies, Hold the Door for businesses',
    url: `${DEFAULT_META.url}${ROUTES.enterprise}`,
  },
  [ROUTES.pricing]: {
    ...DEFAULT_META,
    title: 'Hold the Door - Flexible Pricing Plans for AI-powered Fraud Detection',
    description: 'Choose the perfect pricing plan for your needs with Hold the Door. From free basic plans to advanced options with unlimited fraud checks, our scalable AI-powered anti-fraud solutions offer real-time threat detection, customizable features, and more. Tailor your security with custom solutions and pricing for enterprises.',
    keywords: 'fraud detection pricing, anti-fraud plans, AI fraud prevention pricing, Hold the Door pricing, customizable pricing, fraud prevention plans, free fraud checks, scalable security solutions, enterprise fraud prevention pricing, real-time threat detection pricing',
    url: `${DEFAULT_META.url}${ROUTES.pricing}`,
  },
  [ROUTES.contact]: {
    ...DEFAULT_META,
    title: 'Hold the Door - Contact Us for AI-powered Fraud Prevention Solutions',
    description: 'Get in touch with Hold the Door to learn more about our AI-powered anti-fraud solutions. Whether you need custom pricing, API integration, or have specific security needs, our team is here to help you protect your business from fraud.',
    keywords: 'contact Hold the Door, fraud prevention contact, AI fraud solutions contact, anti-fraud support, business security inquiries, fraud detection assistance, API integration help, Hold the Door support, fraud prevention consultation, online security contact',
    url: `${DEFAULT_META.url}${ROUTES.contact}`,
  },
} as Record<string, IMeta>

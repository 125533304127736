import classNames from 'classnames'

import Heading from '../Heading/Heading'
import Text from '../Text/Text'
import { ReactComponent as AiSvg } from './assets/ai.svg'
import { ReactComponent as DatabaseSvg } from './assets/database.svg'
import { ReactComponent as EasySvg } from './assets/easy.svg'
import { ReactComponent as EfficiencySvg } from './assets/efficiency.svg'
import { ReactComponent as RiskSvg } from './assets/risk.svg'
import { ReactComponent as SecuritySvg } from './assets/security.svg'
import { ReactComponent as SolutionSvg } from './assets/solution.svg'
import { ReactComponent as TailoredSvg } from './assets/tailored.svg'

import styles from './Benefits.module.scss'

const BENEFITS_ICONS = {
  easy: EasySvg,
  database: DatabaseSvg,
  ai: AiSvg,
  tailored: TailoredSvg,
  security: SecuritySvg,
  risk: RiskSvg,
  efficiency: EfficiencySvg,
  solution: SolutionSvg,
}

interface IBenefitsData {
  title: string;
  description: string;
}

type BenefitsValue = 'easy' | 'database' | 'ai' | 'tailored' | 'security' | 'risk' | 'efficiency' | 'solution'

interface IBenefitsProps {
  data: { [key in BenefitsValue]?: IBenefitsData };
}

const Benefits = ({ data }: IBenefitsProps) => (
  <section className={classNames('container section', styles.main)}>
    <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.heading)}>
      Why Hold the Door?
    </Heading>
    <div className={styles.list}>
      {Object.entries(data).map(([key, value]) => {
        const SvgIcon = BENEFITS_ICONS[key as BenefitsValue]

        return (
          <div key={key} className={classNames(styles.wrapper, `card-animated ${key}-animation`)}>
            <div className={classNames('d-flex', styles.item)}>
              <SvgIcon className={styles.svg} />
              <Text className="heading mobile-subheading desktop-subheading">
                {value.title}
              </Text>
              <Text className="text2">
                {value.description}
              </Text>
            </div>
          </div>
        )
      })}
    </div>
  </section>
)

export default Benefits

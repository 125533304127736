import Text from '../Text/Text'

import styles from './Toast.module.scss'

interface IToastProps {
  title: string;
  message: string;
}

const Toast = ({ title, message }: IToastProps) => (
  <div className={styles.main}>
    <Text className={styles.title}>
      {title}
    </Text>
    <Text>
      {message}
    </Text>
  </div>
)

export default Toast

import classNames from 'classnames'
import { Field } from 'react-final-form'

import Text from '../Text/Text'
import FormError from './FormError'
import { getFieldError } from './FormField.utils'

import styles from './Input.module.scss'

interface ITextareaProps {
  id: string;
  label: string;
  className?: string;
  placeholder?: string;
  rows?: number;
  hasResize?: boolean;
}

const Textarea = ({
  label,
  className,
  id,
  rows = 7,
  placeholder = label,
  hasResize = false,
}: ITextareaProps) => (
  <Field
    name={id}
    render={({ input, meta }) => (
      <label
        htmlFor={id}
        className={classNames('d-flex heading-h200', styles.main, !!getFieldError(meta) && styles.error)}
      >
        <Text className={styles.label}>
          {label}
        </Text>
        <textarea
          {...input}
          id={id}
          rows={rows}
          placeholder={placeholder}
          disabled={meta.submitting}
          className={classNames('ui-text', styles.input, className, hasResize && styles.resize)}
        />
        <FormError name={input.name} />
      </label>
    )}
  />
)

export default Textarea

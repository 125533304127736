import { useEffect } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

import ToastContainer from '../components/ToastContainer/ToastContainer'
import ROUTES from '../constants/routes.constants'
import BaseLayout from '../layouts/Base/Base.layout'
import ContactUsRoute from './ContactUs/ContactUs.route'
import EnterpriseRoute from './Enterprise/Enterprise.route'
import HomeRoute from './Home/Home.route'
import NotFoundRoute from './NotFound/NotFound'
import PricingRoute from './Pricing/Pricing.route'

const RoutesWrapper = () => (
  <Routes>
    <Route element={<BaseLayout />}>
      <Route path={ROUTES.home} element={<HomeRoute />} />
      <Route path={ROUTES.enterprise} element={<EnterpriseRoute />} />
      <Route path={ROUTES.pricing} element={<PricingRoute />} />
      <Route path={ROUTES.contact} element={<ContactUsRoute />} />
      <Route path="*" element={<NotFoundRoute />} />
    </Route>
  </Routes>
)

export const Router = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <RoutesWrapper />
      <ToastContainer />
    </>
  )
}

const RouterWrapper = () => (
  <BrowserRouter>
    <Router />
  </BrowserRouter>
)

export default RouterWrapper
